.icon-button svg{
    height: 16px;
    width: 16px;
}
.defaultTableContainer{

}
/* width */
.defaultTableContainer::-webkit-scrollbar {
    height: 5px;
    border-radius: 10px;
    width: 5px
  }
  
  /* Track */
.defaultTableContainer::-webkit-scrollbar-track {
    background: transparent; 
 }
   
  /* Handle */
.defaultTableContainer::-webkit-scrollbar-thumb {
    background: $scroll-color;
    border-radius: 10px;
 }
  
  /* Handle on hover */
.defaultTableContainer::-webkit-scrollbar-thumb:hover {
    background: $scroll-color;
 }
 
 .defaultTableContainer::-webkit-scrollbar-corner{
  display: none;
  visibility: hidden;
 }
 .NoborderCell tbody tr td{
  border: none;
 }

.acceptSelect{
  border: 2px solid #fff !important
}

.MuiTypography-root .required-aster{
  color: $red-color;
  vertical-align: text-bottom;
  top: 4px;
  font-size: 1rem;
  line-height: 1
}

.overRideScroll::-webkit-scrollbar {
  height: 5px;
  border-radius: 10px;
  width: 5px
}

/* Track */
.overRideScroll::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.overRideScroll::-webkit-scrollbar-thumb {
  background: $scroll-color;
  border-radius: 10px;
}

// remove border for input with endAdornment
.removeBorder input[type="password"], .removeBorder input[type="text"]{
  border: none;
}