.meta-data {
    font-size: $heading-font-size;

    p{
        color: $grey-color;
        font-size: $heading-font-size;
    }
}

//Light mode overides
.light{
    .meta-data {
        font-size: $heading-font-size;
    
        p{
            color: $white-color;
        }
        .color-green{
            color: $white-color;
        }
    }
    header{
        .icon-button{
            color: $white-color;
        }
    }
}