// Define Typography here 

body {
    font-family: $font-family;
    font-size: $default-font-size;
}

.color-green {
    color: $green-color;
}

.color-red {
    color: $red-color;
}

.color-yellow {
    color: $yellow-color;
}

.color-grey {
    color: $grey-color;
}

.color-white {
    color: $white-color;
}

.bg-green-light {
    background-color: $light-green-Bg;
}

.bg-red-light {
    background-color: $light-red-Bg;
}

a:hover {
    color: $secondary;
}



/**
** Button Overrides
**/

.b-btn-chk {
    .Mui-checked+.MuiFormControlLabel-label {
        border: 2px solid #fff !important;
    }

    .MuiFormControlLabel-label {
        background: $green-color !important;
        width: auto !important;
        min-width: 64px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: $heading-font-size;
        border: 2px solid transparent !important;
        padding: 2px 14px !important;

        &:hover {
            background-color: rgb(15, 107, 80) !important;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
    }
}

.s-btn-chk {
    .Mui-checked+.MuiFormControlLabel-label {
        border: 2px solid #fff !important;
    }

    .MuiFormControlLabel-label {
        background: $red-color !important;
        width: auto !important;
        min-width: 64px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: $heading-font-size;
        border: 2px solid transparent !important;
        padding: 2px 14px !important;

        &:hover {
            background-color: rgb(142, 46, 52) !important;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
    }
}