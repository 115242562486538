.dashboard-grid .MuiGrid-item {
    margin-bottom: 4px;
}

.dealerResponseTable .MuiTableRow-root>* {
    border-bottom: 1px solid rgba(78, 78, 78, 1);
    min-width: 115px;
    width: 115px;
    max-width: 115px;
}

.historyTable .MuiTableRow-root>* {
    min-width: 115px;

}

.dealerResponseTable .MuiTableRow-root table td {
    font-weight: $font-weight-600;
}