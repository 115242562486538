// Import Google font library Inter font-family 
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);

// Import all the variables
@import './variables';
@import './shared';
@import './typography';
@import './dashboard';
@import './header';
body {}

.App-main, .DealerPanel {
    padding-top: 10px;
    padding-bottom: 10px;
}
div.MuiContainer-root{
    padding: 0 10px;
}

html{
    font-size: $default-HTML-fontsize;
}
// @media (min-width: 1586px){
//     html{
//         font-size: $defaultHTMLFontSize * 1.3;
//     }
// }